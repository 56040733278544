<template>
  <div class="project-sales-booking-documents">
    <h5 class="mb-2">Booking Documents</h5>
    <!-- Download IC & Status -->
    <download-button
      label="IC/Passport"
      :downloadItem="booking.purchaserIdentityCardOrPassport"
      :filenamePrefix="`project_booking_ic_${booking.id}`"
      @download="downloadFile"
    >
    </download-button>
    <!-- Download Saleform & Status -->
    <download-button
      label="Sale Form"
      :downloadItem="booking.saleForm"
      :filenamePrefix="`project_booking_saleform_${booking.id}`"
      @download="downloadFile"
    >
    </download-button>
    <!-- Download Receipt & Status -->
    <download-button
      label="Payment Receipt"
      :downloadItem="booking.paymentReceipt"
      :filenamePrefix="`project_booking_receipt_${booking.id}`"
      @download="downloadFile"
    >
    </download-button>
  </div>
</template>

<script>
import { getFileExtension } from "@/utils/string";

export default {
  components: {
    DownloadButton: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Booking/DownloadButton"
      )
  },
  mixins: [],
  props: {
    booking: {
      type: Object,
      default: () => ({
        purchaserIdentityCardOrPassport: [],
        saleForm: [],
        paymentReceipt: []
      })
    },
    downloadFileAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    async downloadFile(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);

      await this.downloadFileAPI(url, label, ext);
      loading(false);
    }
  }
};
</script>

<style lang="scss"></style>
